import { mapGetters, mapActions } from "vuex"
import ThemisInput from "@/components/shared/input"
import ThemisDecision from "@/components/shared/decision"

export default {
  name      : "IssueFormTemplate",
  components: {
    ThemisInput,
    ThemisDecision
  },
  data() {
    return {
      formTemplate                     : {},
      selectedFormUsage                : null,
      selectedFormApplication          : null,
      issueFormTemplateToBeRemoved     : null,
      showRemoveIssueFormTemplateDialog: false,
      oncePerIssueItemDisabled         : false
    }
  },
  methods: {
    ...mapActions({
      notify                 : "shared/notify",
      updateIssueFormTemplate: "issueFormTemplates/updateIssueFormTemplate",
      removeIssueFormTemplate: "issueFormTemplates/removeIssueFormTemplate"
    }),
    handleRemoveIssueFormTemplate() {
      this.issueFormTemplateToBeRemoved = this.selectedIssueFormTemplate
      this.removeIssueFormTemplate(this.issueFormTemplateId)
    },
    handleUpdateFormApplication() {
      if (this.isFormApplicationChanged) {
        this.updateIssueFormTemplate({
          id       : this.issueFormTemplate.id,
          autoAdded: this.selectedFormApplication
        })
      }
    },
    handleFormUsageChange() {
      if (this.isFormUsageChanged) {
        this.updateIssueFormTemplate({
          id           : this.issueFormTemplate.id,
          multiInstance: this.selectedFormUsage
        })
      }
    }
  },
  computed: {
    ...mapGetters({
      fieldsV2                    : "fields/fieldsV2",
      issueFormTemplates          : "issueFormTemplates/issueFormTemplates",
      formTemplates               : "formTemplates/formTemplates",
      formTemplateConfigurations  : "formTemplateConfigurations/formTemplateConfigurations",
      isIssueFormTemplateRemoved  : "issueFormTemplates/isIssueFormTemplateRemoved",
      isRemovingIssueFormTemplate : "issueFormTemplates/isRemovingIssueFormTemplate",
      isSortingOrderUpdated       : "issueFormTemplates/isSortingOrderUpdated",
      issueFormTemplateUpdateError: "issueFormTemplates/issueFormTemplateUpdateError"
    }),
    issueFormTemplateFieldNames() {
      return this.issueFormTemplateFieldsMap
        ?.get(this.issueFormTemplate.formTemplateId)
        ?.map(field => field?.systemName)
    },
    issueFormTemplateId() {
      return +this.$route.params.id
    },
    issueFormTemplate() {
      return this.issueFormTemplates?.find(issueFormTemplate => issueFormTemplate.id === this.issueFormTemplateId)
    },
    itemsForFormUsage() {
      return [{
        value   : false,
        name    : this.$t("2106"),
        disabled: this.oncePerIssueItemDisabled
      }, {
        value   : true,
        name    : this.$t("2107"),
        disabled: false
      }]
    },
    itemsForFormApplication() {
      return [{
        value: false,
        name : this.$t("2166")
      }, {
        value: true,
        name : this.$t("2167")
      }]
    },
    fieldsMap() {
      const fieldsMap = new Map()
      for (const field of this.fieldsV2) {
        fieldsMap.set(field.id, field)
      }
      return fieldsMap
    },
    issueFormTemplateFieldsMap() {
      const issueFormTemplateFieldsMap = new Map()
      for (const configuration of this.formTemplateConfigurations) {
        let fields = [this.fieldsMap.get(configuration.fieldId)]
        if (issueFormTemplateFieldsMap.has(configuration.formTemplateId)) {
          fields = [...fields, ...issueFormTemplateFieldsMap.get(configuration.formTemplateId)]
        }
        issueFormTemplateFieldsMap.set(configuration.formTemplateId, fields)
      }
      return issueFormTemplateFieldsMap
    },
    selectedIssueFormTemplate() {
      return this.formTemplates.find(formTemplate => formTemplate.id === this.issueFormTemplate?.formTemplateId)
    },
    isFormApplicationChanged() {
      return this.issueFormTemplate?.autoAdded !== this.selectedFormApplication
    },
    isFormUsageChanged() {
      return this.issueFormTemplate?.multiInstance !== this.selectedFormUsage
    }
  },
  watch: {
    issueFormTemplate: {
      immediate: true,
      handler  : function(newValue) {
        if (newValue) {
          this.selectedFormUsage        = newValue.multiInstance,
          this.selectedFormApplication  = newValue.autoAdded
          this.oncePerIssueItemDisabled = false
        }
      }
    },
    isIssueFormTemplateRemoved: {
      handler: function(value) {
        if (value) {
          this.notify({
            type      : "success",
            text      : "2162",
            parameters: {
              issueFormTemplateName: this.issueFormTemplateToBeRemoved?.name
            }
          })
          this.showRemoveIssueFormTemplateDialog = false
          this.issueFormTemplateToBeRemoved      = undefined

          this.$router.push({
            name: "issue-form-templates"
          })
        }
      }
    },
    isRemovingIssueFormTemplate: {
      immediate: false,
      handler  : function(newValue) {
        this.$DECISIONS.REMOVE_ISSUE_FORM_TEMPLATE.pActions[0].buttonProps.disabled = newValue
        this.$DECISIONS.REMOVE_ISSUE_FORM_TEMPLATE.pActions[1].buttonProps.loading  = newValue
      }
    },
    issueFormTemplateUpdateError: {
      handler: function(newValue) {
        if (newValue) {
          this.notify({
            type: "error",
            text: "2179"
          })
          this.selectedFormUsage        = this.issueFormTemplate.multiInstance
          this.oncePerIssueItemDisabled = true
        }
      }
    }
  }
}
